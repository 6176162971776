.Composer-actions .Btn--primary:not(:disabled) {
  background: white !important;
  color: #8a8a8a !important;
  width: 39px;
  height: 39px;
  border-radius: 10px !important;
}

.Composer-actions .IconBtn:not(:disabled) {
  background: white !important;
  color: #8a8a8a !important;
  width: 39px !important;
  height: 39px !important;
  border-radius: 8px !important;
  opacity: 1 !important;
  margin-top: -1px !important;
}

.Composer-actions .Composer-sendBtn:not(:disabled) {
  background: #ABD4FF !important;
  color: #fff !important;
  width: 39px;
  height: 39px;
  border-radius: 8px !important;
  opacity: 1 !important;
  margin-top: -2px !important;
}

.Recorder .Recorder-btn {
  -webkit-touch-callout: none;
  background: var(--white);
  border-radius: 10px;
  color: var(--gray-dark);
  height: 39px !important;
  line-height: 39px;
  text-align: center;
  transition: .3s;
  -webkit-user-select: none;
  user-select: none;
}

.PlayImage {
  width: 25px;
  height: 25px;

  img {
    width: 100%;
    height: 100%;
  }
}


.QuickReply.highlight {
  border-color: #b9d2e8;
}

.QuickReplies {
  background: #e8f4ff !important;
}

.MessageContainer>.PullToRefresh>.PullToRefresh-inner:before {
  background-image: url('../public/images/applogo2.png');
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.1;
  content: '';
  /* position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px; */
}