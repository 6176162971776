body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #EEF7FF !important;
  width: 100vw;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Navbar-title {
  color: var(--gray-1);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  text-align: center;
  white-space: nowrap;
  padding: 5px;
}

.ComponentMessage {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: inherit;
  flex-direction: inherit;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
  justify-content: center;
  position: relative;
}

.MessageContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  min-height: 0;
  position: relative;
  background: #EEF7FF;
}

.Navbar {
  background: #EEF7FF !important;
  border-bottom: 1px solid var(--gray-5);
  padding: 0 var(--gutter);
  padding-top: var(--safe-top);
  position: relative;
  z-index: 10;
  display: none !important;
}

.Composer {
  display: flex;
  padding: 0.5625rem var(--gutter);
  background: #EEF7FF;
  align-items: flex-end;
}

.Message.left .Bubble {
  border-radius: 5px 30px 30px 30px !important;
}

.Message.right .Bubble {
  background: #DDEEFF !important;
  border-radius: 12px;
  margin-left: 2.5rem;
  border-radius: 30px 5px 30px 30px !important;
}

.Recorder-btn {
  -webkit-touch-callout: none;
  background: var(--white);
  border-radius: 10px;
  color: var(--gray-dark);
  height: 40px;
  line-height: 40px;
  text-align: center;
  transition: .3s;
  -webkit-user-select: none;
  user-select: none;
}

.Composer-input {
  background: var(--white);
  border-radius: 10px;
  caret-color: var(--brand-2);
  font-size: .9375rem;
  /* line-height: 40px; */
  min-height: 39px !important;
  /* height: 40px !important; */
  max-height: 60px !important;
  overflow: auto;
  padding: .5rem .75rem;
  transition: border-color .15s ease-in-out;

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

/* 把文本移动到图片上 */
/* .CardTitle {
  padding: 10px 12px !important;
  position: absolute !important;
  bottom: 45px !important;
  color: white !important;
  width: 90% !important;
  font-size: 12px !important;
}

.CardText {
  color: var(--gray-dark) !important;
  padding: 0.75rem !important;
  margin-top: 5px !important;
} */

.CardActions {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute !important;
  background: none !important;
  background: transparent !important;
}

.CardActions .Btn {
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  -webkit-appearance: none;
  background: none !important;
  border: 0px solid var(--gray-5) !important;
  border-radius: 999px;
  color: var(--gray-1);
  display: inline-flex;
  font-family: inherit;
  font-size: .875rem;
  font-weight: 400;
  justify-content: center;
  line-height: 1.5;
  min-width: 5rem;
  overflow: visible;
  padding: 0.3125rem 0.75rem;
  text-align: center;
  text-transform: none;
  transition: .15s ease-in-out;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  background: transparent !important;
  background: inherit !important;
  background: none !important;
  border: none !important;
}

.Avatar img {
  border-radius: 100% !important;
}