.BackgroundImage {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-image: url('../public/images/open2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    top: 0px;
    left: 0px;
}

.BackgroundImageLink {
    width: 40%;
    height: 5%;
    background-color: #e5ecfb;
    border-radius: 100px;
    position: absolute;
    bottom: 25%;
    left: 30%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transform: translateY(60px);
    transition: 1.2s;
}


.BackgroundImageLink.show {
    visibility: visible;
    opacity: 0.95;
    transform: translateY(0);
}

.currentLanguage {
    width: 100%;
    position: absolute;
    top: 60%;
    text-align: center;
    color: white;
}

.currentLanguage span {
    text-align: center;
    color: white;
    border-bottom: 1px white solid;
}

.BackgroundImageText {
    font-size: 20px;
    text-align: center;
    font-weight: 300;
}

.TopInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0px !important;
    top: 50px;
    width: 100%;
    height: 10%;
    text-align: center;
}

.TopText {
    width: 90vw;
    font-size: 18px;
    color: #fff;
    font-weight: 400;
}

.BottomInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0px !important;
    bottom: 20px;
    width: 100vw;
    height: 10%;
    text-align: center;
    padding: 10px;
}

.BottomLogo {
    width: auto;
    height: 40%
}

.BottomText {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
    visibility: hidden;
}

.modal.show {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
    visibility: visible;
}

.modal-content {
    width: 75%;
    max-width: 600px;
    /* 最大宽度，根据需要调整 */
    height: 220px;
    background-color: #e5ecfb;
    border-radius: 10px;
    position: absolute;
    bottom: 30%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 1;
    transform: translateY(250px);
    transition: 0.5s;
}


.modal-content.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

.modal-content_content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* 纵向排列子元素 */
    flex-wrap: wrap;
    align-items: center;
    display: flex;
    justify-content: center;
}

.modal-content-title {
    padding: 10px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    padding: 10px;
    /* margin-top: 10px; */
}

.modal-content-content {
    padding: 10px;
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: column;
    /* 纵向排列子元素 */
    flex-wrap: wrap;
    align-items: center;
}

.modal-content-content div {
    width: 90%;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;
}